.header__link-item{
    font-size: 16px;
    margin: 0 30px;
    text-decoration: none;
    color: #263238;
    font-weight: bold;
    cursor: pointer;
}
.header__link-item:hover{
    transition: .3s ease all;
    color: var(--color-primary);
}

@media screen and (max-width: 800px){
    .header__link-item{
        line-height: 60px;
        border-bottom: solid 1px #E9EFFD;
        margin: 0;
        text-align: center;
        background-color: #fff;
    }
    .header__link-item:nth-child(1){
        border-top: solid 1px #E9EFFD;
    }
}
