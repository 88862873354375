.button{
    text-align: center;
    display: block;
    font-size: 16px;
    transition: .3s ease all;
    padding: 11px 25px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 40px;
    text-decoration: none;
    color: #fff;
    border: solid 2px var(--color-primary);
    background: var(--color-primary);
    cursor: pointer;
}

.button:hover,
.button_outlined{
    color: var(--color-primary);
    background: transparent;
}

.button_outlined:hover{
    color: #fff;
    background: var(--color-primary);
}

@media screen and (max-width: 800px){
    .button{
        padding: 9px 20px;
    }
}
