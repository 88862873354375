.offer-card__chip{
    font-size: 14px;
    background: var(--color-primary);
    color: #fff;
    position: relative;
    border-radius: 10px;
    padding: 0 17px;
    margin-left: 10px;
    text-transform: none;
    white-space: nowrap;
}

.offer-card__chip-star{
    position: absolute;
    right: 2px;
    top: -11px;
    transform-origin: center;
    animation: ease 1s star-blink infinite alternate;
}

@keyframes star-blink {
    from{
        transform: translateY(0) scale(1);
    }
    to{
        transform: translateY(-5px) scale(1.2);
    }
}