.banner{
    height: 100vh;
    min-height: 650px;
}

.banner__content{
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
}

.banner__title{
    text-transform: uppercase;
    font-size: 43px;
    margin-bottom: 50px;
    position: relative;
}

.banner__action{
    padding: 13px 30px;
    margin-bottom: 80px;
    position: relative;
}

@media screen and (max-width: 800px){
    .banner__title{
        text-align: center;
        font-size: 28px;
        margin-bottom: 32px;
    }

    .banner__action{
        padding: 9px 20px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 480px){
    .banner__title{
        text-align: center;
        font-size: 36px;
        margin-bottom: 42px;
    }
}

@media screen and (max-width: 400px){
    .banner__title{
        text-align: center;
        font-size: 36px;
        margin-bottom: 20px;
    }

    .banner__content{
        justify-content: unset;
        padding-top: 50%;
    }
}