:root{
  --color-primary: #6535EC;
  --color-backdrop: rgba(0, 0, 0, .12);
  --color-black: #263238;
  --color-input-field: #DAE3FE;
  --z-index-header: 999;
}

*{
  margin: 0;
  padding: 0;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: var(--color-black);
  box-sizing: border-box;
}

.text_primary{
  color: var(--color-primary);
}