.dialog__backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-backdrop);
    z-index: calc(var(--z-index-header) + 1);

    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    flex-flow: column nowrap;
}

.dialog__backdrop_hidden{
    display: none;
}

.dialog__content{
    background: #fff;
    border: solid 1px var(--color-primary);
    border-radius: 10px;
    display: flex;
    width: 100%;
    padding: 130px 120px 140px;
    font-size: 24px;
    font-weight: bold;
    max-width: 900px;
}

.dialog__buttons{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.dialog__container{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 800px){
    .dialog__content{
        padding: 80px 46px;
        font-size: 16px;
        max-width: 450px;
    }

    .dialog__buttons{
        margin-top: 40px;
    }
}

@media screen and (max-width: 480px){
    .dialog__content{
        padding: 48px 30px;
        font-size: 16px;
        /*max-width: 340px;*/
    }

    .dialog__buttons{
        margin-top: 30px;
    }
}