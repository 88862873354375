.slider__dots{
    margin: 20px 0;
    justify-content: center;
    display: flex;
}

.slider__dot{
    width: 9px;
    height: 9px;
    background: var(--color-primary);
    border-radius: 50%;
    margin: 0 3px;
    transition: all .3s ease;
}

.slider__dot_current{
    background: #00DB99;
}