.questions{
    border-radius: 10px;
    border: solid 1px var(--color-primary);
    padding: 100px 0;
    display: flex;
    position: relative;
}

.questions__container{
    width: calc(100% / 12 * 10 + 30px);
    margin: auto;
}

.questions__header{
    padding-bottom: 40px;
}

.questions__content{
    padding: 40px 0;
}

@media screen and (max-width: 800px){
    .questions{
        padding: 45px 0;
    }

    .questions__container{
        width: calc(100% / 12 * 10 + 30px);
    }

    .questions__content{
        padding: 30px 0;
    }

    .questions__header{
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 480px){
    .questions__container{
        width: 100%;
        padding: 0 17px;
    }

    .questions__header{
        padding: 0 5px;
    }
}