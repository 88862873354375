.page__section{
    padding: 80px 0;
}

@media screen and (max-width: 800px){
    .page__section{
        padding: 60px 0;
    }
}

@media screen and (max-width: 480px){
    .page__section{
        padding: 30px 0;
    }
}