.advantages{
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
}

.advantages__content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 50px 0;
}

@media screen and (max-width: 800px){
    .advantages__content{
        display: block;
    }
}
