.banner__container{
    position: relative;
    height: 100vh;
    min-height: 650px;
}

/*@media screen and (max-width: 800px) and (orientation:landscape) {*/
/*    .banner__container{*/
/*    }*/
/*}*/


/*@media screen and (max-width: 800px){*/
/*    .banner__container{*/
/*        max-height: 650px;*/
/*    }*/
/*}*/

@media screen and (max-width: 480px){
    .banner__container{
        max-height: 100vh;
    }
}