.offers{
    position: relative;
}

.offers__header{
    padding-top: 100px;
    display: flex;
}

.offers__image{
    width: calc(100% / 3);
    padding: 0 15px;
}

.offers__title{
    flex: 1 1 auto;
    align-items: center;
    display: flex;
    padding: 0 30px;
}

.offers__content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /*grid-template-rows: repeat(2, 1fr);*/
    gap: 30px;
    padding-top: 80px;
}

@media screen and (max-width: 800px){
    .offers__image{
        width: calc(100% / 12 * 5);
    }

    .offers__content{
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 30px;
        padding-top: 60px;
    }
}

@media screen and (max-width: 480px){
    .offers__content{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        padding: 40px 0;
    }

    .offers__image{
        display: none;
    }

    .offers__header{
        padding: 0;
    }

    .offers__title{
        padding: 0;
    }
}