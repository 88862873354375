:root{
    --z-index-parallax-layer-base: 4;
    --z-index-parallax-layer-back: -1;
    --z-index-parallax-layer-deep: -2;
    --z-index-parallax-layer-fore: 10;
}
.parallax__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
}
.parallax__layer_base {
    position: relative;
    transform: translateZ(0);
    z-index: var(--z-index-parallax-layer-base);
}
.parallax__layer_back {
    transform: translateZ(-100px) scale(2);
    z-index: var(--z-index-parallax-layer-back);
}
.parallax__layer_deep {
    transform: translateZ(-10px) scale(1.1);
    z-index: var(--z-index-parallax-layer-deep);
}
.parallax__layer_fore {
     transform: translateZ(35px) scale(0.65);
     z-index: var(--z-index-parallax-layer-fore);
}
