.container{
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
}

@media screen and (max-width: 480px){
    .container{
        padding: 0 20px;
    }
}
