.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before{
    width: 28px;
    height: 2px;
    background-color: var(--color-primary) !important;
}

.hamburger{
    padding: 0;
    height: 28px;
}

.hamburger-box{
    width: 28px;
    height: 28px;
}