.page__header{
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
}

@media screen and (max-width: 800px){
    .page__header{
        font-size: 24px;
    }
}

@media screen and (max-width: 480px){
    .page__header{
        font-size: 20px;
        max-width: 100%;
    }
}