.text-field{
    margin-bottom: 20px;
}

.text-field_helped{
    margin-bottom: 5px;
}

.input-field{
    display: flex;
    font-size: 16px;
    position: relative;
}

.input-field__input{
    background: var(--color-input-field);
    padding: 18px 14px;
    border: solid 1px var(--color-input-field);
    border-radius: 10px;
    font-size: inherit;
    flex: 1 1 auto;
}

.input-field__input_wrong{
    border-color: #E8505B;
}

.input-field__input_focused{
    outline: none;
}

.input-field__input_textarea{
    resize: none;
}

.input-field__label{
    color: #455A64;
    position: absolute;
    left: 16px;
    top: 17px;
    opacity: 70%;
    transform-origin: 0;
    transition: all .3s ease;
}

.input-field__label_valid
{
    transform: translateY(-13px);
    font-size: 10px;
}

.input-field__label-icon{
    opacity: 1;
    transition: all .3s ease;
}

.input-field__label_valid .input-field__label-icon
{
    opacity: 0;
}

.text-field__helper-text{
    color: var(--color-black);
    font-size: 14px;
}

@media screen and (max-width: 800px){
    .input-field{
        font-size: 14px;
    }

    .input-field__input{
        padding: 17px 16px;
    }

    .text-field{
        margin-bottom: 10px;
    }
}