.offer-card{
    background: #E9EFFD;
    border-radius: 10px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.offer-card__image{
    height: 200px;
    justify-content: center;
    display: flex;
    width: 100%;
}

.offer-card__title{
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    padding: 15px 0;
    display: flex;
    align-items: center;
    line-height: 34px;
}

.offer-card__description{
    font-size: 16px;
    min-height: 150px;
    margin-bottom: auto;
}

.offer-card__action{
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.offer-card_focusable{
    transform-origin: center;
    transition: transform .3s ease;
}

.offer-card_focusable:hover{
    transform: scale(1.03) !important;
}

@media screen and (max-width: 480px){
    .offer-card_focusable:hover{
        transform: scale(1);
    }

    .offer-card__description{
        height: auto;
        margin-bottom: 40px;
    }
}