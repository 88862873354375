.slick-slide{
    transform: scale(0.83);
    transition: all .2s ease;
}

.slick-slide.slick-active{
    transform: scale(1);
}

.slick-dots li button:before{
    opacity: 1 !important;
    width: 9px;
    height: 9px;
    background: var(--color-primary);
    border-radius: 50%;
    margin: 40px 3px 0;
    transition: all .3s ease;
    content: "";
}
.slick-dots li{
    margin: 0;
}

.slick-dots li.slick-active button:before{
    background: #00DB99;
}