.accordion{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.accordion__header{
    align-items: center;
    background: #E9EFFD;
    border-radius: 10px;
    text-transform: uppercase;
    padding: 26px 35px;
    cursor: pointer;
}
.accordion__header *{
    color: var(--color-primary);
}
.accordion__header-text{
    font-weight: bold;
    margin-right: 15px;
    font-size: 22px;
}
.accordion__content{
    padding: 0 40px;
    margin: 20px 0;
    overflow: hidden;
    transition: all .3s ease;
    min-height: 0;
    font-size: 18px;
}
.accordion__content_hidden{
    margin: 0 !important;
}
.accordion__header-angle{
    font-size: 15px;
    transition: all .3s ease;
    transform-origin: center;
}
.accordion__header-angle_expanded{
    transform: rotate(180deg);
}

.accordion_theme_secondary .accordion__header{
    text-transform: none;
    background: unset;
    color: inherit;
    padding: 15px 0;
}
.accordion_theme_secondary .accordion__header *{
    color: inherit
}
.accordion_theme_secondary .accordion__content{
    padding: 0;
}

@media screen and (max-width: 800px){
    .accordion{
        margin-bottom: 10px;
    }

    .accordion__header{
        padding: 16px 15px;
    }

    .accordion__header-text{
        margin-right: 10px;
        font-size: 15px;
    }

    .accordion__header-angle{
        font-size: 13px;
    }

    .accordion_theme_secondary .accordion__header{
        padding: 15px 0;
    }

    .accordion__content{
        padding: 0 20px;
        margin: 5px 0;
        font-size: 15px;
    }
}