.slider{
    width: 100%;
}

.slider__container{
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
}
.slider__viewport{
    position: absolute;
    display: grid;
}