.contact-us{
    position: relative;
}

.contact-us__header{
    padding-left: 15px;
}

.contact-us__content{
    display: grid;
    grid-template-columns: 7fr 5fr;
    gap: 30px;
}

.contact-us__fields{
    display: flex;
    align-items: center;
}

.contact-us__button{
    margin-top: 10px;
}

@media screen and (max-width: 800px){
    .contact-us__content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .contact-us__fields{
        padding-top: 30px;
    }
}

@media screen and (max-width: 480px){
    .contact-us__content{
        display: flex;
        flex-direction: column-reverse;
        gap: 0;
    }

    .contact-us__header{
        padding: 0;
    }
}