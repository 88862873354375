.banner__image{
    position: absolute;
}

.banner__image_floor{
    bottom: 7px;
    right:15px;
    width: 744px;
    height: 2px;
}

.banner__image_woman{
    bottom:0;
    right: 170px
}

.banner__image_screen{
    right:455px;
    bottom: 90px;
}

.banner__image_screen_small{
    top: 195px;
    opacity: 0.5;
    transform-origin: top left;
    transform: scale(0.85);
}

.banner__image_plant{
    bottom: 10px;
}

.banner__image_chart{
    right: 15px;
    top: 360px;
    width: 57px;
    height: 49px;
}

@media screen and (max-width: 800px){
    .banner__image_floor{
        bottom: 24px;
        right: 5px;
        width: 434px;
    }

    .banner__image_woman{
        bottom: 20px;
        right: 105px;
        width: 229px;
        height: 270px;
    }

    .banner__image_screen{
        right: 50%;
        width: 146px;
        height: 98px;
    }

    .banner__image_screen_small{
        opacity: 0.5;
        width: 157px;
        height: 100px;
        transform: scale(1);
    }

    .banner__image_plant{
        bottom: 20px;
        height: 222px;
        width: 112px;
    }

    .banner__image_chart{
        right: 15px;
        top: 50%;
        width: 39px;
        height: 33px;
    }
}

@media screen and (max-width: 480px){
    .banner__image_floor{
        bottom: 62px;
        right: 12px;
        width: 333px;
    }

    .banner__image_woman{
        bottom: 58px;
        right: 30px;
        width: 211px;
        height: 250px;
    }

    .banner__image_screen{
        right: auto;
        left: 22px;
        bottom: 162px;
        width: 131px;
        height: 88px;
    }

    .banner__image_screen_small{
        top: 150px;
        opacity: 0.5;
        width: 125px;
        height: 84px;
        left: 50%;
        transform: scale(1);
    }

    .banner__image_plant{
        display: none;
    }

    .banner__image_chart{
        right: 23px;
        top: 55%;
        width: 29px;
        height: 25px;
    }
}