.advantage-card{
    border-radius: 10px;
    padding: 20px 30px;
    border: solid 1px var(--color-primary);
    flex-direction: column;
    background: #fff;
}

.advantage-card__image-container{
    height: 280px;
    display: flex;
}

.advantage-card__image{
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.advantage-card__text{
    height: 60px;
    padding: 50px 0;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    text-align: center;
    justify-content: center;
}

.advantage-card_focusable{
    transform-origin: center;
    transition: transform .3s ease;
}

.advantage-card_focusable:hover{
    transform: scale(1.03);
}

@media screen and (max-width: 800px){
    .advantage-card_focusable:hover{
        transform: scale(1);
    }
}

@media screen and (max-width: 480px){
}