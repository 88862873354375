:root{
    --z-index-parallax-group-base: 4;
    --z-index-parallax-group-fore: 4;
}
.parallax__group {
    position: relative;
    min-height: 100vh;
    transform-style: preserve-3d;
    z-index: var(--z-index-parallax-group-base);
}
.parallax__group_fore {
    z-index: var(--z-index-parallax-group-fore);
}