@media screen and (max-width: 800px){
    .header__menu{
        position: fixed;
        z-index: calc( var(--z-index-header) - 1);
        display: none;
    }

    .header__menu_opened{
        background-color: var(--color-backdrop);
        width: 100vw;
        height: 100vh;
        top:0;
        left: 0;
        margin-top: 76px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 480px){
    .header__menu_opened{
        margin-top: 83px;
    }
}