.header{
    padding: 20px 15px 15px 0;
    width: 100%;
    position: fixed;
    align-items: center;
    top: 0;
    z-index:var(--z-index-header);
    background: #fff;
}

.header__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header__logo{
    color: var(--color-primary);
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 24px;
    flex-shrink: 0;
}

.header__burger{
    height: 28px;
    width: 28px;
    display: none;
}

.header-right-view{
    display: flex;
    gap: 36px;
    align-items: center;
}

@media screen and (max-width: 800px){
    .header{
        padding: 20px 0 15px 0;
    }

    .header__burger{
        display: block;
    }
}

@media screen and (max-width: 480px){
    .header{
        padding: 40px 0 15px 0;
    }

    .header__button{
        display: none;
    }

    .header__logo{
        font-size: 20px;
    }
}